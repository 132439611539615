import * as React from "react";
import { Link } from "gatsby";
import { observer } from 'mobx-react';
import Layout from "../../components/layout";
import useStores from "../../hooks/use-stores";
// @ts-ignore
import detailIcon from "../../images/detailIcon.png";
// @ts-ignore
import recordIcon from "../../images/recordIcon.png";
// import  from "../images/.png";

const propertyNatureEnum = ["unknown", "Residential", "Commercial"]
const propertyTypeEnum = ["unknown", "House", "Apartment", "Offices", "Retail", "Land/Development", "Industrial/Warehouse", "Commercial Farming"]

const StockPage = observer(() => {
  const { propertyStore } = useStores();
  React.useEffect(() => {
    propertyStore.getPropertyList();
  }, []);
  return (
    <Layout>
      <h6>
        Current Location: Housing Stock - <span className="font-semibold">Sell</span>
      </h6>
      {/* input to search property */}
      <section>
        <form className="flex flex-wrap justify-between [&>*]:my-3 [&>*>*]:mx-1">
          <span>
            <input className="w-60" type="text" placeholder=" ⌕ Search property location.." />
            <input type="checkbox" id="includeAround" name="includeAround" />
            <label htmlFor="includeAround">Include others around</label>
          </span><span>
            <label htmlFor="houseType">House type</label>
            <select name="houseType" id="houseType">
              <option value="any">House</option>
              <option value="House">Apartment</option>
            </select>
          </span><span>
            <label htmlFor="car">Car</label>
            <select name="car" id="car">
              <option value="any">any</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
            <label htmlFor="bedroom">Bedroom</label>
            <select name="bedroom" id="bedroom">
              <option value="any">any</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
            <label htmlFor="bathroom">Bathroom</label>
            <select name="bathroom" id="bathroom">
              <option value="any">any</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </span>
          <span>
            <label htmlFor="priceMin priceMax">Price Range</label>
            <input className="w-24" type="number" id="priceMin" name="priceMin" />
            -
            <input className="w-24" type="number" id="priceMax" name="priceMax" />
          </span><span>
            <label htmlFor="floorMin floorMax">Floor Size</label>
            <input className="w-24" type="number" id="floorMin" name="floorMin" />
            -
            <input className="w-24" type="number" id="floorMax" name="floorMax" />
          </span><span>
            <button className="red-button">
              <div className="mx-6 bg-icon-2 bg-[url('../images/whiteSearch.png')]">Query</div></button>
            <button className="white-button">
              <div className="mx-6 bg-icon-2 bg-[url('../images/redRefresh.png')]">Reset</div></button>
          </span>
        </form>
      </section>
      {/* property table */}
      <section>
        <div className="flex justify-between">
          <h1>SELL LISTINGS</h1>
          <Link className="red-button inline" to="/property/detail">New Property</Link>
        </div>
        <table className="w-full">
          <thead>
            <tr className="bg-theme-light">
              <th>ID</th>
              <th>Title</th>
              <th>Nature</th>
              <th>Type</th>
              <th>Bed/Bath/Car</th>
              <th>Floor size</th>
              <th>Land size</th>
              <th>Location</th>
              <th>Price info</th>
              <th>Last update</th>
              <th>Detail</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {propertyStore.propertyList.map((property, index) =>
              <tr key={index}>
                <td>{property.propertyId}</td>
                <td>{property.title.length > 25 ? property.title.substring(0, 25) + "..." : property.title}</td>
                <td>{propertyNatureEnum[parseInt(property.propertyNature)]}</td>
                <td>{propertyTypeEnum[parseInt(property.propertyType)]}</td>
                <td>{property.bedrooms}/{property.bathrooms}/{property.carSpaces}</td>
                <td>{property.floorSize}m<sup>2</sup></td>
                <td>{property.landSize}m<sup>2</sup></td>
                <td>{property.address.streetNumber}&nbsp;{property.address.streetName}</td>
                <td>{property.priceInfo.length > 15 ? property.priceInfo.substring(0, 15) + "..." : property.priceInfo}</td>
                <td>{property.updateAt.split("T")[0]}</td>
                <td><img className="w-8 py-1 px-1" src={detailIcon} /></td>
                <td><img className="w-8 py-1 px-1" src={recordIcon} /></td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
    </Layout >
  );
});

export default StockPage;

export const Head = () => <title>Real estate | Stock</title>;
